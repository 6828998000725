// eslint-disable-next-line import/prefer-default-export
export const EPersonTypeName = {
  PHYSICAL: "Cédula Física",
  JURIDICAL: "Cédula Jurídica ",
  DIMEX: "DIMEX",
  NITE: "NITE",
  FOREIGN: "Cédula Extranjera",
};

export const EPersonTypeCode = {
  PHYSICAL: "01",
  JURIDICAL: "02",
  DIMEX: "06",
  NITE: "07",
  FOREIGN: "05",
};
