/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";
// Graphql
import { useMutation } from "@apollo/client";
import { USER_BY_BILLING_RECEIVER } from "../../graphql";
import { PhoneInput } from "../Common";
// Context
import { useElectronic } from "../../context/ElectronicContext";
import { useAuth } from "../../context";
// Utils
import { formatData, getIsUserRegisteredInHacienda } from "../../utils/helpers";
import { customToast as toast, validateEmail } from "../../utils";
import { validateID } from "../../utils/idDocument";
// Components
import InputForm from "../InputForm/InputForm";
import FormWithButtons from "../FormWithButttons/FormWithButtons";
import SelectForm from "../SelectForm/SelectForm";
import SimpleGrid from "../SimpleGrid";
// Enums
import { EPersonTypeName } from "../../Enums/PersonType";
// SCSS
import "./RegisterReceiver.scss";

function RegisterReceiver({
  setClient,
  handleChangeAddress,
  clientList,
  initialReceiver,
  title,
  FK_User,
  setBillingProfile,
  newClient,
  handleCreateNewClient,
  handleOnClosed,
  onFormSaved,
  isAdmin,
}) {
  const [receiver, setReceiver] = useState({
    id: null,
    FK_User,
    Name: "",
    IDForeign: "",
    Lastname: "",
    MH_PersonType: "1",
    ID_Number: "",
    Email: "",
    PhoneNumber: "",
    PhoneCodCountry: "506",
    MH_Province: "",
    MH_Canton: "",
    MH_District: "",
    MH_Neighborhood: "",
    OtherSigns: "",
    Tax_Registration: isAdmin ? "" : undefined,
  });
  const [IDForeign, setIDForeign] = useState(!!initialReceiver?.IDForeign);

  const {
    personTypeList,
    provinces,
    cantones,
    districts,
    neighborhoods,
    getDistricts,
    getNeighborhoods,
    getCantons,
    patchBillingProfile,
    reverseBillingName,
    getUserLocation,
    countries,
  } = useElectronic();
  const [registerReceiver] = useMutation(USER_BY_BILLING_RECEIVER);
  const { setState } = useAuth();

  const isFormValid =
    receiver.Name !== "" &&
    receiver.Lastname !== "" &&
    receiver.MH_PersonType !== "" &&
    (IDForeign ? receiver.IDForeign !== "" : receiver.ID_Number !== "") &&
    receiver.Email !== "" &&
    receiver.PhoneNumber !== "" &&
    receiver.PhoneCodCountry !== "" &&
    receiver.MH_Province !== "" &&
    receiver.MH_Canton !== "" &&
    receiver.MH_District !== "" &&
    receiver.MH_Neighborhood !== "" &&
    receiver.OtherSigns !== "" &&
    (isAdmin ? receiver.Tax_Registration !== "" : true);

  const getPersonTypeName = personTypeId =>
    personTypeList.find(({ id }) => id === personTypeId);
  const isForeignPersonType = personTypeName =>
    personTypeName === EPersonTypeName.FOREIGN;

  const personType = getPersonTypeName(receiver.MH_PersonType);

  useEffect(() => {
    if (!initialReceiver) return;

    const { Name, Lastname } = reverseBillingName(initialReceiver?.Name);
    setReceiver({
      FK_User: FK_User.toString(),
      id: initialReceiver?.id,
      Name: Name || "",
      Lastname: Lastname || "",
      MH_PersonType: initialReceiver?.MH_PersonType?.id || "1",
      IDForeign: initialReceiver?.IDForeign || "",
      ID_Number: initialReceiver?.ID_Number || "",
      Email: initialReceiver?.Email || "",
      PhoneNumber: initialReceiver?.PhoneNumber || "",
      PhoneCodCountry: initialReceiver?.PhoneCodCountry || "",
      MH_Province: initialReceiver?.MH_Province?.id || "",
      MH_Canton: initialReceiver?.MH_Canton?.id || "",
      MH_District: initialReceiver?.MH_District?.id || "",
      MH_Neighborhood: initialReceiver?.MH_Neighborhood?.id || "",
      OtherSigns: initialReceiver?.OtherSigns || "",
      Tax_Registration: isAdmin
        ? initialReceiver?.Tax_Registration || ""
        : undefined,
    });
    getUserLocation(
      initialReceiver?.MH_Province?.id,
      initialReceiver?.MH_Canton?.id,
      initialReceiver?.MH_District?.id,
    );
  }, [initialReceiver, FK_User]);

  useEffect(() => {
    setReceiver(prev => ({ ...prev, FK_User }));
  }, [FK_User]);

  useEffect(() => {
    if (!receiver?.MH_PersonType) return;

    if (!personType) return;

    setIDForeign(isForeignPersonType(personType.Name_PersonType));
  }, [receiver?.MH_PersonType]);

  const handleChange = (name, value) => {
    setReceiver(prev => ({ ...prev, [name]: value }));
    if (name === "MH_Province") {
      getCantons(value);
    } else if (name === "MH_Canton") {
      getDistricts(value);
    } else if (name === "MH_District") {
      getNeighborhoods(value);
    }
  };

  const handleSave = async e => {
    e.preventDefault();
    if (!isFormValid) {
      toast.error("Debe completar todos los datos solicitados.");
      return;
    }
    if (!validateEmail(receiver.Email)) {
      toast.error("El correo electrónico no tiene la estructura correcta.");
      return;
    }
    const idValidationMessage = validateID({
      id: receiver.ID_Number,
      idType: receiver.MH_PersonType,
    });
    if (idValidationMessage) {
      toast.error(idValidationMessage);
      return;
    }

    const isATV = await getIsUserRegisteredInHacienda(receiver.ID_Number);
    if (isATV || !isAdmin) {
      if (FK_User) {
        receiver.Name = `${receiver.Name} ${receiver.Lastname}`;
        delete receiver.Lastname;
        const BillingProfile = await patchBillingProfile(receiver);

        setBillingProfile({
          MH_User: initialReceiver?.MH_User,
          ...BillingProfile,
        });
        toast.success("Perfil de facturación actualizado.", {
          toastId: "billingProfile",
        });

        setState(prev => ({
          ...prev,
          user: {
            ...prev.user,
            TavuelUser: {
              ...prev.user.TavuelUser,
              BillingProfile: BillingProfile.id,
            },
          },
        }));
      } else if (!newClient) {
        register();
      } else {
        const isInList = clientList?.find(
          index => index?.BillingProfile?.ID_Number === receiver.ID_Number,
        );
        if (isInList) {
          toast.error(
            "Ya existe un usuario con el número de identificación proporcionado asociado al grupo.",
          );
        } else {
          const { id, ...restReceiver } = receiver;
          handleCreateNewClient(restReceiver);
        }
      }
      if (handleOnClosed) {
        handleOnClosed();
      }
      onFormSaved?.();
    } else {
      toast.error(
        "Este número de identificación no se encuentra inscrito en el Ministerio de Hacienda.",
      );
    }
  };

  const register = async () => {
    try {
      const { data } = await registerReceiver({
        variables: {
          profile: receiver,
        },
      });

      setClient(prev => ({
        ...prev,
        id: data.billingProfile.id,
        Receiver_Id: receiver.ID_Number,
        Receiver_Name: `${receiver.Name} ${receiver.Lastname}`,
        Receiver_Email: receiver.Email,
        Receiver_PhoneCode: receiver.PhoneCodCountry,
        Receiver_PhoneNumber: receiver.PhoneNumber,
        Receiver: "",
        Receiver_PersonType: receiver.MH_PersonType,
      }));
      handleChangeAddress(
        "Province",
        data.billingProfile.MH_Neighborhood.District.Canton.Province.Name,
      );
      handleChangeAddress(
        "Canton",
        data.billingProfile.MH_Neighborhood.District.Canton.Name,
      );
      handleChangeAddress(
        "District",
        data.billingProfile.MH_Neighborhood.District.Name,
      );
      handleChangeAddress(
        "Neighborhood",
        data.billingProfile.MH_Neighborhood.Name,
      );
      handleChangeAddress("Other", data.billingProfile.OtherSigns);
      toast.success("¡Cliente registrado con éxito!");
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <form className="content-flex-column">
      <Typography
        fontWeight="bold"
        sx={{ marginY: "1rem" }}
        textAlign="center"
        typography="modeColor"
        variant="h6"
      >
        {title}
      </Typography>
      <SimpleGrid>
        <SelectForm
          color="secondary"
          data={formatData(personTypeList, "Name")}
          label="Tipo de identificación:"
          name="MH_PersonType"
          size="small"
          value={receiver?.MH_PersonType}
          onChange={e => handleChange(e.target.name, e.target.value)}
        />
        <InputForm
          color="primary"
          id="ID_Number"
          label="Identificación"
          name={IDForeign ? "IDForeign" : "ID_Number"}
          size="small"
          type="string"
          value={IDForeign ? receiver?.IDForeign : receiver?.ID_Number}
          onChange={e => handleChange(e.target.name, e.target.value)}
        />
        <InputForm
          color="primary"
          id="NameUser"
          label="Nombre"
          name="Name"
          size="small"
          value={receiver?.Name}
          onChange={e => handleChange(e.target.name, e.target.value)}
        />
        <InputForm
          color="primary"
          id="Lastname"
          label="Apellidos"
          name="Lastname"
          size="small"
          value={receiver?.Lastname}
          onChange={e => handleChange(e.target.name, e.target.value)}
        />
        <InputForm
          color="primary"
          id="Email"
          label="Correo Electrónico"
          name="Email"
          size="small"
          type="email"
          value={receiver?.Email}
          onChange={e => handleChange(e.target.name, e.target.value)}
        />
        <PhoneInput
          code={receiver?.PhoneCodCountry}
          codeKey="PhoneCodCountry"
          countries={countries}
          phone={receiver?.PhoneNumber}
          phoneKey="PhoneNumber"
          placeholder="Número telefónico:"
          size="small"
          onChange={handleChange}
        />
        <SelectForm
          color="secondary"
          data={formatData(provinces, "Name")}
          label="Provincia"
          name="MH_Province"
          placeholder="Seleccionar la provincia"
          size="small"
          value={receiver?.MH_Province}
          onChange={e => handleChange(e.target.name, e.target.value)}
        />
        <SelectForm
          color="secondary"
          data={formatData(cantones, "Name")}
          label="Cantón"
          name="MH_Canton"
          placeholder="Seleccionar el cantòn"
          size="small"
          value={receiver?.MH_Canton}
          onChange={e => handleChange(e.target.name, e.target.value)}
        />
        <SelectForm
          color="secondary"
          data={formatData(districts, "Name")}
          label="Distrito"
          name="MH_District"
          placeholder="Seleccionar el distrito"
          size="small"
          value={receiver?.MH_District}
          onChange={e => handleChange(e.target.name, e.target.value)}
        />
        <SelectForm
          color="secondary"
          data={formatData(neighborhoods, "Name")}
          label="Barrio"
          name="MH_Neighborhood"
          placeholder="Barrio"
          size="small"
          value={receiver?.MH_Neighborhood}
          onChange={e => handleChange(e.target.name, e.target.value)}
        />
        <InputForm
          color="primary"
          id="Name"
          label="Otras señas"
          name="OtherSigns"
          size="small"
          type="textarea"
          value={receiver?.OtherSigns}
          onChange={e => handleChange(e.target.name, e.target.value)}
        />
        {isAdmin && (
          <InputForm
            color="primary"
            label="Registrofiscal8707"
            name="Tax_Registration"
            size="small"
            type="textarea"
            value={receiver?.Tax_Registration}
            onChange={e => handleChange(e.target.name, e.target.value)}
          />
        )}
      </SimpleGrid>
      <FormWithButtons
        buttonsAlignment="end"
        onCancel={handleOnClosed}
        onSubmit={handleSave}
      />
    </form>
  );
}

RegisterReceiver.propTypes = {
  handleChangeAddress: PropTypes.func.isRequired,
  setClient: PropTypes.func,
  clientList: PropTypes.any,
  initialReceiver: PropTypes.any,
  title: PropTypes.any.isRequired,
  FK_User: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setBillingProfile: PropTypes.func.isRequired,
  newClient: PropTypes.bool,
  handleCreateNewClient: PropTypes.func,
  handleOnClosed: PropTypes.func,
  onFormSaved: PropTypes.func,
  isAdmin: PropTypes.bool,
};

RegisterReceiver.defaultProps = {
  initialReceiver: null,
  FK_User: null,
  clientList: null,
  handleCreateNewClient: () => null,
  setClient: () => null,
  newClient: false,
  handleOnClosed: () => null,
  onFormSaved: undefined,
  isAdmin: false,
};

export default RegisterReceiver;
