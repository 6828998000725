/* eslint-disable consistent-return */
import { EPersonTypeCode } from "../Enums/PersonType";

const doesTextHaveDashes = text => /[-]/.test(text);
const doesTextHaveZeroAtBeginning = text => /^0/.test(text);

export const validatePhysicalId = id => {
  if (doesTextHaveZeroAtBeginning(id))
    return "El ID físico no debe comenzar con un cero.";
  if (doesTextHaveDashes(id)) return "El ID físico no debe contener guiones.";
  if (id.length !== 9)
    return "El ID físico debe tener exactamente 9 dígitos numéricos.";
};

export const validateJuridicalId = id => {
  if (doesTextHaveDashes(id)) return "El ID jurídico no debe contener guiones.";
  if (id.length !== 10)
    return "El ID jurídico debe tener exactamente 10 dígitos numéricos.";
};

export const validateDimexId = id => {
  if (doesTextHaveZeroAtBeginning(id))
    return "El ID Dimex no debe comenzar con un cero.";
  if (doesTextHaveDashes(id)) return "El ID Dimex no debe contener guiones.";
  if (id.length < 11 || id.length > 12)
    return "El ID Dimex debe tener 11 o 12 dígitos numéricos.";
};

export const validateNiteId = id => {
  if (doesTextHaveDashes(id)) return "El NITE no debe contener guiones.";

  if (id.length !== 10)
    return "El NITE debe tener exactamente 10 dígitos numéricos.";
};

export const validateID = ({ id, idType }) => {
  if (typeof id !== "string" || typeof idType !== "string") return undefined;

  const fixedIdType = `0${idType}`;
  id.trim();

  if (!/^[a-zA-Z0-9\s-]+$/.test(id))
    return "El ID no puede contener caracteres especiales";
  if (fixedIdType === EPersonTypeCode.PHYSICAL) return validatePhysicalId(id);
  if (fixedIdType === EPersonTypeCode.JURIDICAL) return validateJuridicalId(id);
  if (fixedIdType === EPersonTypeCode.DIMEX) return validateDimexId(id);
  if (fixedIdType === EPersonTypeCode.NITE) return validateNiteId(id);

  return "Tipo de ID no válido.";
};
