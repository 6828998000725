import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// External components
import {
  Box,
  Button,
  Typography,
  Chip,
  CircularProgress,
  Card,
} from "@mui/material";
// Graphql
import { useQuery } from "@apollo/client";
import { GET_CLIENTS_BY_PLACE } from "../../graphql";
// Utils
import { customToast as toast, validateEmail } from "../../utils";
// SCSS
import "./SendBillScreen.scss";
import FormWithButtons from "../FormWithButttons/FormWithButtons";
import SearchInput from "../Products/components/SearchInput";

function SendBillScreen({
  place,
  handleCloseModal,
  billingEmail,
  handleSend,
  receptorRequired,
}) {
  const [receivers, setReceivers] = useState([]);
  const [searchForm, setSearchForm] = useState("");
  const { loading, data: clientsByIdCompany } = useQuery(GET_CLIENTS_BY_PLACE, {
    variables: {
      FK_Place: place?.id,
    },
  });
  useEffect(() => {
    if (billingEmail) {
      setReceivers([billingEmail]);
    }
  }, [billingEmail]);

  const addClientEmail = (email = searchForm) => {
    if (
      email.length === 0 ||
      receivers.find(receiver_email => receiver_email === email) ||
      !validateEmail(email)
    )
      return;
    setReceivers(prev => [...prev, email]);
    setSearchForm("");
  };

  const removeClientEmail = email => {
    setReceivers(prev => [
      ...prev.filter(receiver_email => receiver_email !== email),
    ]);
  };

  const SendEmails = async () => {
    try {
      await handleSend(receivers);
      handleCloseModal();
    } catch (e) {
      toast.error("Error al enviar el email");
    }
  };

  return (
    <Box
      className="container-modal modal-content-padding"
      sx={{
        height: "100%",
        maxWidth: "800px",
        width: { xs: "350px", sm: "600px", md: "800px" },
      }}
    >
      <Typography typography="modeColor" variant="h6" width="100%">
        Receptores
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          overflow: "auto",

          width: { sm: "800px" },
          gap: "1rem",
        }}
      >
        {receivers.map(client => (
          <Chip
            key={client}
            color="primary"
            label={client}
            sx={{
              "fontSize": "14px",
              "& .MuiChip-deleteIcon": {
                "color": "#fff",
                "&:hover": {
                  color: "#fff",
                },
              },
            }}
            onDelete={() => removeClientEmail(client)}
          />
        ))}
        {!receivers.length && (
          <Typography typography="modeColor" variant="body1" width="100%">
            Selecciona o agrega un receptor
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "end", sm: "center" },
          justifyContent: "space-between",
          gap: "1rem",
          width: "100%",
        }}
      >
        <SearchInput
          className="full-width-input-search"
          color="secondary"
          label="Correo del receptor"
          value={searchForm}
          onChange={({ target: { value } }) => setSearchForm(value)}
        />

        <Button
          color="secondary"
          sx={{ width: { xs: "97px" }, height: "48px" }}
          variant="contained"
          onClick={() => addClientEmail()}
        >
          Agregar
        </Button>
      </Box>

      {!loading &&
        clientsByIdCompany?.client?.filter(_client =>
          _client.BillingProfile.Email.includes(searchForm),
        ).length === 0 && (
          <Typography typography="modeColor" variant="body1" width="100%">
            No se encontraron Clientes
          </Typography>
        )}

      {loading && <CircularProgress disableShrink />}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          overflow: "auto",
          height: "53px",
        }}
      >
        {clientsByIdCompany?.client
          ?.filter(
            _client =>
              _client.BillingProfile.Email.includes(searchForm) &&
              !receivers.includes(_client.BillingProfile.Email),
          )
          ?.map(_client => (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: { sm: "1rem" },
              }}
            >
              <Card
                color="whiteOnly"
                sx={{
                  height: "48px",
                  padding: "15px",
                  width: "100%",
                  cursor: "pointer",
                }}
              >
                <Typography
                  key={_client.id}
                  sx={{ fontSize: "14px" }}
                  onClick={() => addClientEmail(_client.BillingProfile.Email)}
                >
                  {_client.BillingProfile.Name}
                </Typography>
              </Card>
              <Box sx={{ width: { sm: "97px" } }} />
            </Box>
          ))}
      </Box>
      <FormWithButtons
        buttonsAlignment="start"
        cancelText="Cerrar"
        disableSubmit={receptorRequired && !receivers.length}
        submitText="Enviar"
        onCancel={() => handleCloseModal()}
        onSubmit={() => SendEmails()}
      />
    </Box>
  );
}
SendBillScreen.propTypes = {
  place: PropTypes.object.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  billingEmail: PropTypes.string,
  handleSend: PropTypes.func.isRequired,
  receptorRequired: PropTypes.bool,
};
SendBillScreen.defaultProps = {
  billingEmail: null,
  receptorRequired: false,
};

export default SendBillScreen;
