import React, { useEffect, useMemo } from "react";
import { useQuery } from "@apollo/client";
// external component
import { Card, Typography, Divider, Box } from "@mui/material";
// Context
import { useNavigate } from "react-router-dom";
import { useElectronic } from "../../context/ElectronicContext";
import {
  addressInitialState,
  expenseFormInitialState,
} from "../../InitialStates/ElectronicContext";
import { useAuth, useModal } from "../../context";
// component
import OptionCard from "../OptionCard";
import { SummaryExpenses } from "./components";
import ClientCard from "../ClientCard";
import RegisterReceiver from "../RegisterReceiver";
import CabysCodeSelect from "../CabysCodeSelect/CabysCodeSelect";
import InputForm from "../InputForm/InputForm";
import SelectForm from "../SelectForm/SelectForm";
import FormWithButtons from "../FormWithButttons/FormWithButtons";
// Hooks
import useUsers from "../../hooks/useUsers";
import useBuyBill from "../../hooks/useBuyBill";
// Enums
import { PROVIDER_GROUP } from "../../Enums/General";
import {
  BILL_TYPES_URL_PARAMS,
  ElectronicBillTypes,
  TAX_CODES,
} from "../../Enums/ElectronicBill";
// Utils
import { customToast } from "../../utils";
import {
  getDiscountAmout,
  getSubTotal,
  getTaxedAmount,
  getTotal,
} from "../../utils/Operations";
// SCSS
import "./BuyElectronicBill.scss";
import { formatData, validateRoute } from "../../utils/helpers";
import {
  GET_ALL_COINS,
  GET_ALL_PAYMENT_METHODS,
  GET_ALL_SELL_CONDITIONS,
} from "../../graphql";

function BuyElectronicBill() {
  const {
    LineDetailPlace,
    receiver,
    useCoinDetail,
    infoSummary,
    setInfoSummary,
    summaryId,
    setSummaryId,
    expenseForm,
    setExpenseForm,
    electronicBill,
    handleChangeElectronicBill,
    tariff,
    getAllUnitOfMeasure,
    taxesData,
    setReceiver,
    setAddress,
    setClientGroup,
    resetReceiver,
    isBilling,
    setIsBilling,
  } = useElectronic();

  const { data: getPaymentMethodData } = useQuery(GET_ALL_PAYMENT_METHODS);
  const { data: getCoinsData } = useQuery(GET_ALL_COINS);
  const { data: getSellConditionData } = useQuery(GET_ALL_SELL_CONDITIONS);

  const { state: authState } = useAuth();

  const { addNewClient, dataClientsGroups, createGroup, refetchGroups } =
    useUsers();
  const { setModalOpen } = useModal();
  const navigate = useNavigate();
  const { handleCreatDraftFEC, handleCreateFEC, handleCreateFECProducts } =
    useBuyBill();
  const {
    currentSymbolCoin,
    toExchangeRate,
    resetDefaultCoin,
    getCoins,
    toDolar,
    currentCoin,
    handleCoinChange,
  } = useCoinDetail;

  const resetProductForm = () => {
    setExpenseForm({
      ...expenseFormInitialState,
    });
    setSummaryId(summaryId + 1);
  };

  const handleChange = (name, value) =>
    setExpenseForm(prev => ({ ...prev, [name]: value }));

  const handleChangeIVAAdapter = (name, tarif_id) =>
    handleChange(
      name,
      tariff.tariff.find(_tariff => _tariff.id === tarif_id),
    );

  const handleChangeTasxAdapter = (name, tax_id) =>
    handleChange(
      name,
      taxesData.taxes.find(_tax => _tax.id === tax_id),
    );

  const handleChangeMeasurementUnitAdapter = (name, mu_id) =>
    handleChange(
      name,
      getAllUnitOfMeasure?.unitsOfMeasurement.find(mu => mu.id === mu_id),
    );

  const isValidProduct = () =>
    expenseForm.quantity > 0 &&
    expenseForm.expense.length !== 0 &&
    expenseForm.amount > 0 &&
    !!expenseForm.tax &&
    !!expenseForm.cabys &&
    isValidBaseTax(expenseForm) &&
    isValidFactor(expenseForm) &&
    isValidIva(expenseForm);

  const isValidIva = form => {
    if (form?.tax?.MH_Code === TAX_CODES.IVA_BIENES_USADOS) return true;
    return !!form.tariff;
  };
  const isValidBaseTax = form => {
    if (form?.tax?.MH_Code !== TAX_CODES.IVA_CALCULO_ESPECIAL) return true;
    return form.baseTax > 0;
  };
  const isValidFactor = form => {
    if (form?.tax.MH_Code !== TAX_CODES.IVA_BIENES_USADOS) return true;
    return form.factor > 1;
  };

  const addNewExpense = () => {
    if (!isValidProduct()) {
      customToast.error(
        "Error al agregar el gasto, revisa que los campos estén llenos correctamente.",
      );
      return;
    }
    const discountAsDecimal = expenseForm.discount / 100 || 0;
    const subTotal =
      getSubTotal(expenseForm.amount, discountAsDecimal) * expenseForm.quantity;
    const tariffAmount =
      getTaxedAmount({
        taxCode: expenseForm.tax.MH_Code,
        subTotal,
        porcentajeTarifa: expenseForm.tariff.Percent,
        baseTax: expenseForm.baseTax || 0,
        factor: expenseForm.factor || 0,
      }) * expenseForm.quantity;
    const discountedAmount =
      getDiscountAmout(expenseForm.amount, discountAsDecimal) *
      expenseForm.quantity;
    setInfoSummary([
      ...infoSummary,
      {
        id: summaryId,
        name: expenseForm.expense,
        quantity: expenseForm.quantity,
        measurmentUnit: expenseForm.measurmentUnit,
        discountPercent: expenseForm.discount || 0,
        tariff: expenseForm.tariff,
        tax: expenseForm.tax,
        factor: expenseForm.factor || 0,
        cabys: expenseForm.cabys,
        unitPrice: toDolar(expenseForm.amount),
        subTotal: toDolar(subTotal),
        baseTax: toDolar(expenseForm.baseTax) || 0,
        tariffAmount: toDolar(tariffAmount),
        disAmount: toDolar(discountedAmount),
        total: toDolar(getTotal(subTotal, tariffAmount)),
      },
    ]);
    resetProductForm();
  };

  useEffect(() => {
    if (!isBilling) {
      resetReceiver();
      resetDefaultCoin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => {
      if (isBilling) setIsBilling(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isBilling],
  );

  const totalsResume = useMemo(
    () =>
      infoSummary.reduce(
        (acc, info) => {
          acc[0].value += parseFloat(info.subTotal);
          acc[1].value += parseFloat(info.disAmount);
          acc[2].value += info.tariffAmount;
          return acc;
        },
        [
          { label: "Subtotal: ", value: 0 },
          { label: "Descuento: ", value: 0 },
          { label: "IVA: ", value: 0 },
        ],
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getTaxedAmount, infoSummary],
  );

  useEffect(() => {
    setInfoSummary(prev =>
      prev.map(pr => ({
        ...pr,
        amo: parseFloat(pr.unitPrice),
        dis: parseFloat(pr.dis),
      })),
    );
  }, [getCoins?.coins, expenseForm.typeAmount, setInfoSummary]);

  const handleOpenModal = () => {
    setModalOpen(
      true,
      <Box
        className="modal-content-size container-modal "
        sx={{
          height: { xs: "600px", sm: "100%" },
          width: { xs: "333px", sm: "100%" },
        }}
      >
        <RegisterReceiver
          newClient
          FK_User={0}
          handleChangeAddress={() => null}
          handleCreateNewClient={addNewClientAddapter}
          handleOnClosed={() => setModalOpen(false)}
          initialReceiver={null}
          setBillingProfile={null}
          title="Perfil de facturación"
        />
      </Box>,
    );
  };

  const addNewClientAddapter = async profile => {
    const providersGroup = dataClientsGroups?.groups?.find(
      ({ Name_ClientGroup }) => Name_ClientGroup === PROVIDER_GROUP,
    );
    const selectedGroup =
      providersGroup?.id ||
      (
        await createGroup({
          Name_ClientGroup: PROVIDER_GROUP,
          FK_Place: LineDetailPlace.id,
        })
      )?.clientGroup?.id;
    refetchGroups();
    if (!selectedGroup) {
      return customToast.error("Ocurrió un error al crear el grupo");
    }
    const newClient = await addNewClient(profile, selectedGroup, "Proveedor");
    const client = newClient?.data?.client;
    const billingProfile = {
      id: client?.BillingProfile?.id,
      Receiver_Id: client?.BillingProfile?.ID_Number,
      Receiver_Name: client?.BillingProfile?.Name,
      Receiver_Email: client?.BillingProfile?.Email,
      Receiver_PhoneCode: client?.BillingProfile?.PhoneCodCountry,
      Receiver_PhoneNumber: client?.BillingProfile?.PhoneNumber,
      Receiver_PersonType: client?.BillingProfile?.MH_PersonType,
      user: { id: client?.BillingProfile?.User.id },
    };
    const address = {
      Province:
        client?.BillingProfile?.MH_Neighborhood?.District?.Canton?.Province,
      Canton: client?.BillingProfile?.MH_Neighborhood?.District?.Canton,
      District: client?.BillingProfile?.MH_Neighborhood?.District,
      Neighborhood: client?.BillingProfile?.MH_Neighborhood,
      Other: client?.BillingProfile?.OtherSigns,
    };

    setAddress(address);
    setClientGroup({ id: 0 });
    setModalOpen(false);
    return setReceiver(billingProfile);
  };

  const handleChangeCabys = cabys => {
    setExpenseForm(prev => ({
      ...prev,
      cabys,
      tariff: tariff.tariff.find(_tariff => _tariff.Percent === cabys.percent),
    }));
  };

  const isValidPayload = () =>
    receiver?.id &&
    infoSummary?.length > 0 &&
    electronicBill.Sell_Condition &&
    electronicBill.Payment_Method;

  const createDraftBill = async () => {
    const savedProducts = await handleCreateFECProducts({
      FK_User: authState.user.TavuelUser.id,
      productInput: infoSummary,
    });

    const savedDraftBill = await handleCreatDraftFEC(
      savedProducts?.data?.createFECProducts,
    );

    if (!savedDraftBill?.data?.createDraftFEC) {
      customToast.error(
        'Ocurrió un error al generar la factura. Se guardó una copia en "Borradores"',
      );
    }

    return savedDraftBill;
  };

  const createPurchaseBill = async () => {
    if (!isValidPayload()) {
      return customToast.error("Por favor complete todos los datos");
    }
    const savedDraftBill = await createDraftBill();

    const savedEBill = await handleCreateFEC(
      savedDraftBill?.data?.createDraftFEC?.id,
    );
    if (savedEBill?.data?.createFECBill?.error) {
      return customToast.error(
        "Ocurrió un error al guardar la factura electrónica",
      );
    }
    customToast.success("Se ah guardado exitosamente la factura electronica");
    return navigate(
      `/ElectronicBillView/${savedEBill.data.createFECBill.id}/created/${BILL_TYPES_URL_PARAMS.BUY_BILL}}`,
    );
  };

  const handleResetProvider = () => {
    resetReceiver();
    setAddress(addressInitialState);
  };

  const validateBill = () => {
    if (infoSummary?.length === 0 || receiver?.Receiver_Id === "") return true;
    return false;
  };

  return (
    <div className="content-flex-column">
      <Box
        sx={{
          gap: "1rem",
          display: "flex",
          flexDirection: receiver?.Receiver_Id
            ? "column"
            : { xs: "column", md: "row" },
          width: "100%",
        }}
      >
        <OptionCard
          img={validateRoute(LineDetailPlace)}
          optional={LineDetailPlace.Name_Place}
          title="Tienda"
        />
        <ClientCard
          data={{
            Id: receiver?.Receiver_Id,
            Name: receiver?.Receiver_Name,
            Email: receiver?.Receiver_Email,
            PhoneNumber: receiver?.Receiver_PhoneNumber,
          }}
          icon="user-plus"
          icon2="address-book"
          type={ElectronicBillTypes.FacturaElectronicaDeCompra}
          onClickCancel={() => handleResetProvider()}
          onClickSubmit={() => handleOpenModal()}
        />
      </Box>

      {/* AGREGAR GASTOS */}
      <Card
        color="primary"
        sx={{
          minHeight: "184px",
          display: "flex",
          flexDirection: "column",
          padding: "1rem",
          gap: "1rem",
          width: "100%",
        }}
      >
        <Typography
          fontWeight={600}
          typography="modeColor"
          variant="h5"
          width="100%"
        >
          Agregar gasto
        </Typography>
        <div className="content-grid-column">
          <InputForm
            key="expense"
            color="primary"
            id="expense"
            label="Gasto"
            name="expense"
            size="small"
            type="text"
            value={expenseForm.expense}
            onChange={({ target: { value, name } }) =>
              handleChange(name, value)
            }
          />
          <SelectForm
            color="secondary"
            data={formatData(taxesData?.taxes, "Description_Tax")}
            label="Tipo de valor agregado"
            name="tax"
            size="small"
            value={expenseForm?.tax?.id}
            variant="filled"
            onChange={event =>
              handleChangeTasxAdapter(event.target.name, event.target.value)
            }
          />
          {expenseForm?.tax?.MH_Code === "08" && (
            <InputForm
              key="factor"
              color="primary"
              id="factor"
              label="Monto factor"
              name="factor"
              placeholder="0"
              size="small"
              type="number"
              value={expenseForm.Factor}
              onChange={({ target: { value, name } }) =>
                handleChange(name, value)
              }
            />
          )}
        </div>
        <div className="content-grid-column">
          <InputForm
            key="quantity"
            color="primary"
            id="quantity"
            label="Cantidad de productos"
            name="quantity"
            size="small"
            type="number"
            value={expenseForm.quantity || ""}
            onChange={({ target: { value, name } }) =>
              handleChange(name, value)
            }
          />
          <CabysCodeSelect
            handleChange={handleChangeCabys}
            selected_value={{
              value: expenseForm.cabys?.value,
              label: expenseForm.cabys?.label || "Buscar Código cabys",
            }}
          />
        </div>
        <div className="content-grid-column">
          <SelectForm
            color="secondary"
            data={formatData(
              getAllUnitOfMeasure?.unitsOfMeasurement,
              "Name_UnitOfMeasurement",
            )}
            label="Unidad de medida"
            name="measurmentUnit"
            size="small"
            value={expenseForm?.measurmentUnit.id || ""}
            variant="filled"
            onChange={e =>
              handleChangeMeasurementUnitAdapter(e.target.name, e.target.value)
            }
          />
          {!["08"].includes(expenseForm?.tax?.MH_Code) && (
            <SelectForm
              color="secondary"
              data={formatData(tariff?.tariff, "Name")}
              label="IVA%"
              name="tariff"
              size="small"
              value={expenseForm?.tariff?.id || ""}
              variant="filled"
              onChange={e =>
                handleChangeIVAAdapter(e.target.name, e.target.value)
              }
            />
          )}
        </div>
        <div className="content-grid-column">
          <InputForm
            key="quantity"
            color="primary"
            id="amount"
            label="Costo"
            name="amount"
            placeholder="0"
            size="small"
            type="number"
            value={expenseForm?.amount || ""}
            onChange={({ target: { value, name } }) =>
              handleChange(name, value)
            }
          />
          <InputForm
            key="discount"
            color="primary"
            id="discount"
            label="Descuento %"
            name="discount"
            placeholder="0%"
            size="small"
            type="number"
            value={expenseForm?.discount || ""}
            onChange={({ target: { value, name } }) =>
              handleChange(name, value)
            }
          />
          {expenseForm?.tax?.MH_Code === "07" && (
            <InputForm
              key="quantity"
              color="primary"
              id="baseTax"
              label="Base disponible"
              name="baseTax"
              placeholder="0"
              size="small"
              type="number"
              value={expenseForm.baseTax}
              onChange={({ target: { value, name } }) =>
                handleChange(name, value)
              }
            />
          )}
        </div>
        <Card />
        <FormWithButtons
          buttonsAlignment="end"
          disableSubmit={!isValidProduct()}
          submitText="Agregar"
          onCancel={() => {
            setExpenseForm({
              ...expenseFormInitialState,
            });
          }}
          onSubmit={addNewExpense}
        />
      </Card>
      <Box
        sx={{
          gap: "1rem",
          display: "flex",
          flexDirection:
            infoSummary.length > 0
              ? "column-reverse"
              : { xs: "column", md: "row" },
          width: "100%",
        }}
      >
        {/* DATOS DE PAGO */}
        <Box
          className="content-grid-column"
          sx={{ justifyContent: "center", width: "100%" }}
        >
          <Card
            color="primary"
            sx={{
              minHeight: "184px",
              display: "flex",
              flexDirection: "column",
              padding: "1rem",
              gap: "1rem",
            }}
          >
            <Typography
              fontWeight={600}
              typography="modeColor"
              variant="h5"
              width="100%"
            >
              Datos de Pago
            </Typography>
            <SelectForm
              color="secondary"
              data={formatData(
                getPaymentMethodData?.paymentMethod,
                "Name_PaymentMethod",
              )}
              label="Método de pago"
              name="Payment_Method"
              size="small"
              value={electronicBill.Payment_Method}
              variant="filled"
              onChange={event => {
                handleChangeElectronicBill(
                  event.target.name,
                  event.target.value,
                );
                handleChange(event.target.name, event.target.value);
              }}
            />
            {electronicBill?.Payment_Method === "6" && (
              <InputForm
                key="OtherPaymentMethod"
                color="primary"
                id="OtherPaymentMethod"
                label="Monto"
                name="OtherPaymentMethod"
                size="small"
                type="text"
                value={electronicBill.OtherPaymentMethod}
                onBlur={({ target: { name, value } }) =>
                  handleChange(name, value)
                }
                onChange={e =>
                  handleChangeElectronicBill(e.target.name, e.target.value)
                }
              />
            )}
            <SelectForm
              color="secondary"
              data={formatData(getCoinsData.coins, "Name_Coin")}
              label="Tipo de moneda"
              name="Coin"
              size="small"
              value={currentCoin}
              variant="filled"
              onChange={event => {
                handleChangeElectronicBill(
                  event.target.name,
                  event.target.value,
                );
                handleChange(event.target.name, event.target.value);
                handleCoinChange(event.target.value);
              }}
            />
            <SelectForm
              color="secondary"
              data={formatData(
                getSellConditionData?.sellConditions,
                "Name_SellCondition",
              )}
              label="Condición de venta"
              name="Sell_Condition"
              size="small"
              value={electronicBill.Sell_Condition}
              variant="filled"
              onChange={event => {
                handleChangeElectronicBill(
                  event.target.name,
                  event.target.value,
                );
                handleChange(event.target.name, event.target.value);
              }}
            />
            {electronicBill?.Sell_Condition === "10" && (
              <InputForm
                key="OtherSellCondition"
                color="primary"
                id="OtherSellCondition"
                label="Especifique la condición de venta"
                name="OtherSellCondition"
                size="small"
                type="text"
                value={electronicBill.OtherSellCondition}
                onBlur={({ target: { name, value } }) =>
                  handleChange(name, value)
                }
                onChange={e =>
                  handleChangeElectronicBill(e.target.name, e.target.value)
                }
              />
            )}
            {electronicBill?.Sell_Condition === "2" && (
              <InputForm
                key="CreditPaymentCondition"
                color="primary"
                id="CreditPaymentCondition"
                label="Indique la cantidad de días del plazo del crédito"
                name="CreditPaymentCondition"
                size="small"
                type="number"
                value={electronicBill.CreditPaymentCondition}
                onBlur={({ target: { name, value } }) =>
                  handleChange(name, value)
                }
                onChange={e =>
                  handleChangeElectronicBill(e.target.name, e.target.value)
                }
              />
            )}
          </Card>
          <Box
            sx={{
              display: infoSummary.length <= 0 ? "none" : "flex",
              alignItems: "center",
              height: "100%",
              justifyContent: "end",
            }}
          >
            <FormWithButtons
              buttonsAlignment="end"
              cancelText="Guardar en borrador"
              disableSubmit={validateBill()}
              submitText="Generar"
              onCancel={() => createDraftBill()}
              onSubmit={createPurchaseBill}
            />
          </Box>
        </Box>
        {/* RESUMEN */}
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Card
            color="primary"
            sx={{
              minHeight: "184px",
              display: "flex",
              flexDirection: "column",
              padding: "1rem",
              gap: "1rem",
            }}
          >
            <Typography
              fontWeight={600}
              typography="modeColor"
              variant="h5"
              width="100%"
            >
              Resumen
            </Typography>
            <Box
              sx={{
                "display": "flex",
                "gap": "1rem",
                "flexDirection": "row",
                "overflowX": "auto",
                "paddingBottom": "0.2rem",
                "&::-webkit-scrollbar": {
                  height: "5px",
                  cursor: "pointer",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "rgba(57, 110, 156, 1)",
                  borderRadius: "8px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "transparent",
                },
              }}
            >
              {infoSummary.length > 0 &&
                infoSummary.map(info => (
                  <SummaryExpenses
                    key={info.id}
                    amount={toExchangeRate(info.unitPrice)}
                    discount={toExchangeRate(info.disAmount)}
                    expense={info.name}
                    id={info.id}
                    iva={toExchangeRate(info.tariffAmount).toFixed(3)}
                    measurmentUnit={info.measurmentUnit}
                    quantity={info.quantity}
                    setInfoSummary={setInfoSummary}
                    subTotal={toExchangeRate(info.subTotal)}
                    symbolCoin={currentSymbolCoin}
                    total={toExchangeRate(info.total)}
                  />
                ))}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "1rem",
                  flexDirection: "column",
                  maxWidth: totalsResume
                    ? { xs: "100%", md: "28.125rem" }
                    : "100%",
                  flexGrow: 1,
                }}
              >
                {totalsResume?.map(item => (
                  <Typography
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    typography="modeColor"
                    variant="subtitle1"
                    width="100%"
                  >
                    <b>{item.label}</b>
                    {currentSymbolCoin}
                    {toExchangeRate(item.value).toFixed(3)}
                  </Typography>
                ))}
                <Divider color="tertiary" />
                <Typography
                  sx={{ display: "flex", justifyContent: "space-between" }}
                  typography="modeColor"
                  variant="subtitle1"
                  width="100%"
                >
                  <b>Total</b>
                  {currentSymbolCoin}
                  {toExchangeRate(
                    getTotal(totalsResume[0].value, totalsResume[2].value),
                  ).toFixed(3)}
                </Typography>
              </Box>
            </Box>
          </Card>
          <Box sx={{ display: infoSummary.length > 0 && "none" }}>
            <FormWithButtons
              buttonsAlignment="end"
              cancelText="Guardar en borrador"
              disableSubmit={validateBill()}
              submitText="Generar"
              onCancel={() => createDraftBill()}
              onSubmit={createPurchaseBill}
            />
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default BuyElectronicBill;
