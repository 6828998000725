import { Box } from "@mui/material";
import TitledCard from "./TitledCard";
import { OptionCard, SimpleTable } from "../../../components";
import addProductIcon from "../../../assets/icons/add_product_icon.svg";
import ExpandableCard from "./ExpandableCard";
import { useElectronic } from "../../../context/ElectronicContext";

function ExportationProductDataDisplayer() {
  const { useCoinDetail, lineDetail } = useElectronic();
  const { toExchangeRate } = useCoinDetail;

  const rows = lineDetail?.map((line, index) => {
    const amount = line.quantity * line.unit_price;
    const ivaAmount = (amount * line.Tariff.Percent) / 100;
    const discountsSum = line.discount.reduce(
      (sum, discountPercentage) => sum + parseFloat(discountPercentage),
      0,
    );
    const total = amount + ivaAmount;

    return [
      index + 1,
      line.cabys.Code,
      line.label,
      line.quantity,
      line.unit_Measure.Name_UnitOfMeasurement,
      toExchangeRate(line.unit_price).toFixed(3),
      toExchangeRate(amount).toFixed(3),
      discountsSum,
      toExchangeRate(total).toFixed(3),
    ];
  });

  const headerRow = [
    "Linea",
    "Código",
    "Detalle del producto",
    "Cantidad",
    "Unidad",
    "Precio unitario",
    "Monto",
    "Descuento",
    "Total",
  ];

  return (
    <TitledCard
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <OptionCard
          hideTitle
          mainComponent
          coinDetails={[useCoinDetail]}
          icon={addProductIcon}
          sx={{ width: "34px", height: "34px", padding: 1 }}
          title="Añadir productos"
        />
      </Box>
      <SimpleTable
        dataRows={rows}
        headerRow={headerRow}
        sx={{
          display: { xs: "none", md: "flex" },
        }}
      />
      <Box
        sx={{
          display: { xs: "flex", md: "none" },
          flexDirection: "column",
          gap: 1,
        }}
      >
        {rows.map((rowData, index) => (
          <ExpandableCard
            headerContent={
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  fontWeight: "bold",
                }}
              >
                <span>
                  {`${index + 1}. `}
                  {rowData[2]}
                </span>
                <span>{rowData[8]}</span>
              </Box>
            }
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              {headerRow.map((col, colsIndex) => (
                <Box sx={{ display: "flex", gap: 1 }}>
                  <span>
                    <b>{col}:</b>
                  </span>
                  <span>{rowData[colsIndex]}</span>
                </Box>
              ))}
            </Box>
          </ExpandableCard>
        ))}
      </Box>
    </TitledCard>
  );
}

export default ExportationProductDataDisplayer;
