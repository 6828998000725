import { Grid } from "@mui/material";
import PropTypes from "prop-types";

function QuickGrid({ data }) {
  return (
    <Grid container spacing={2}>
      {data.map(({ label, value }) => (
        <Grid item md={6} xs={12}>
          <Grid container spacing={{ xs: 0, sm: 2 }}>
            <Grid item sm={4} xs={12}>
              <span>
                <b>{label}:</b>
              </span>
            </Grid>
            <Grid item sm={8} xs={12}>
              <span
                style={{
                  wordBreak: "break-word",
                }}
              >
                {value}
              </span>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

QuickGrid.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    }),
  ).isRequired,
};

export default QuickGrid;
