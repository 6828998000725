import { BILL_TYPES_URL_PARAMS } from "../Enums/ElectronicBill";

export const formatElectronicBillDetailsFormattedWithMaxQuantity =
  _electronicBill => {
    const detailsAccumulated = Array.isArray(_electronicBill.CreditNotes)
      ? _electronicBill.CreditNotes.reduce(
          (accumulator, { ElectronicBillDetail }) => [
            ...accumulator,
            ...ElectronicBillDetail,
          ],
          [],
        ).reduce((accumulator, detail) => {
          const temporalProductFeature = accumulator?.find(
            x => x.FK_ProductFeature === detail.FK_ProductFeature,
          );
          if (temporalProductFeature) {
            temporalProductFeature.maxQuantity += detail.Quantity;
            temporalProductFeature.accPrice += detail.Price * detail.Quantity;
          } else {
            accumulator.push({
              FK_ProductFeature: detail.FK_ProductFeature,
              totalQuantity: detail.Quantity,
              accPrice: detail.Price * detail.Quantity,
            });
          }
          return accumulator;
        }, [])
      : [];
    return {
      ..._electronicBill,
      ElectronicBillDetail: _electronicBill?.ElectronicBillDetail.map(
        detail => {
          const temporalProductFeature = detailsAccumulated.find(
            detailAcc =>
              detailAcc.FK_ProductFeature === detail.FK_ProductFeature,
          );

          return {
            ...detail,
            Quantity:
              detail.Quantity - (temporalProductFeature?.totalQuantity || 0),
            valid:
              detail.Price * detail.Quantity -
                (temporalProductFeature?.accPrice || 0) >
              0,
            maxQuantity:
              detail.Quantity - (temporalProductFeature?.totalQuantity || 0),
          };
        },
      ),
    };
  };

export const EBTypeToTypesEnum = BillType => {
  if (BillType === "Factura Electronica")
    return BILL_TYPES_URL_PARAMS.ELECTRONIC_BILL;
  if (BillType === "Nota de Debito") return BILL_TYPES_URL_PARAMS.DEBIT_NOTE;
  if (BillType === "Nota de Credito") return BILL_TYPES_URL_PARAMS.CREDIT_NOTE;
  if (BillType === "Tiquete Electronico")
    return BILL_TYPES_URL_PARAMS.ELECTRONIC_BILL;
  if (BillType === "Confirmacion Comprobante Electronico") return "NotDefined";
  if (BillType === "Confirmacion Parcial Comprobante Electronico")
    return "NotDefined";
  // if(BillType==='Rechazo Comprobante Electronico') return ''
  return "NotDefined";
};

export const castElectronicBillToIncomeXml = dbIncome => ({
  id: dbIncome.id,
  Clave: {
    _text: dbIncome.Key,
  },
  CodigoActividad: {
    _text: dbIncome.ActivityCode,
  },
  NumeroConsecutivo: {
    _text: dbIncome.InternConsecutive,
  },
  FechaEmision: {
    _text: new Date(dbIncome?.EmitedDay).toISOString(),
  },
  Receptor: {
    Nombre: { _text: dbIncome.Bill?.User?.UserBillingProfile?.Name },
    Identificacion: {
      Tipo: {
        _text:
          dbIncome.Bill?.User?.UserBillingProfile?.MH_PersonType
            ?.Name_PersonType,
      },
      Numero: { _text: dbIncome.Bill?.User?.UserBillingProfile?.ID_Number },
    },
    NombreComercial: {
      _text: dbIncome.Bill?.User?.UserBillingProfile?.ComercialName,
    },
    Ubicacion: {
      Provincia: {
        _text: dbIncome.Bill?.User?.UserBillingProfile?.MH_Province?.id,
      },
      Canton: {
        _text: dbIncome.Bill?.User?.UserBillingProfile?.MH_Canton?.id,
      },
      Distrito: {
        _text: dbIncome.Bill?.User?.UserBillingProfile?.MH_District?.id,
      },
      OtrasSenas: {
        _text: dbIncome.Bill?.User?.UserBillingProfile?.MH_Neighborhood?.Name,
      },
    },
    CorreoElectronico: {
      _text: dbIncome.Bill?.User?.UserBillingProfile?.Email,
    },
  },
  Emisor: {
    Nombre: {
      _text: dbIncome.Place?.Company?.User?.UserBillingProfile?.Name,
    },
    Identificacion: {
      Tipo: {
        _text:
          dbIncome.Place?.Company?.User?.UserBillingProfile?.MH_PersonType
            ?.Name_PersonType,
      },
      Numero: {
        _text: dbIncome.Place?.Company?.User?.UserBillingProfile?.ID_Number,
      },
    },
    NombreComercial: {
      _text: dbIncome.Place?.Company?.User?.UserBillingProfile?.ComercialName,
    },
    Ubicacion: {
      Provincia: {
        _text:
          dbIncome.Place?.Company?.User?.UserBillingProfile?.MH_Province?.id,
      },
      Canton: {
        _text: dbIncome.Place?.Company?.User?.UserBillingProfile?.MH_Canton?.id,
      },
      Distrito: {
        _text:
          dbIncome.Place?.Company?.User?.UserBillingProfile?.MH_District?.id,
      },
      OtrasSenas: {
        _text:
          dbIncome.Place?.Company?.User?.UserBillingProfile?.MH_Neighborhood
            ?.Name,
      },
    },
    CorreoElectronico: {
      _text: dbIncome.Place?.Company?.User?.UserBillingProfile?.Email,
    },
  },
  CondicionVenta: {
    _text: "---",
  },
  MedioPago: {
    _text: "---",
  },
  DetalleServicio: {
    LineaDetalle: dbIncome.Bill.Detail?.map((line, index) => ({
      NumeroLinea: {
        _text: index + 1,
      },
      Codigo: {
        _text: "---",
      },
      Cantidad: {
        _text: line.Quantity_BillDetail,
      },
      UnidadMedida: {
        _text: line.UnitOfMeasurement.Symbol,
      },
      Detalle: {
        _text: dbIncome.Bill?.Detail?.[0]?.ProductFeature?.Product.Name_Product,
      },
      PrecioUnitario: {
        _text: line.UnitPrice,
      },
      MontoTotal: {
        _text: line.Total_BillDetail,
      },
      SubTotal: {
        _text: line.SubTotal_BillDetail,
      },
      Impuesto: {
        Codigo: {
          _text: "---",
        },
        CodigoTarifa: {
          _text: "---",
        },
        Tarifa: {
          _text: "---",
        },
        Monto: {
          _text: "---",
        },
      },
      MontoTotalLinea: {
        _text: "---",
      },
    })),
  },
  ResumenFactura: {
    CodigoTipoMoneda: {
      CodigoMoneda: {
        _text: dbIncome?.Coin?.Code_Coin,
      },
      TipoCambio: {
        _text: dbIncome?.Coin?.Buy_Coin,
      },
    },
    TotalMercanciasGravadas: {
      _text: "---",
    },
    TotalGravado: {
      _text: "---",
    },
    TotalVenta: {
      _text: "---",
    },
    TotalVentaNeta: { _text: dbIncome.TotalNetSell },
    TotalImpuesto: {
      _text: "---",
    },
    TotalComprobante: {
      _text: "---",
    },
  },
  Otros: {
    OtroTexto: {
      _text: "---",
    },
  },
});
