import PropTypes from "prop-types";
import { Box, Button, Card, IconButton, Typography } from "@mui/material";
import { format } from "date-fns";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
import { BillUploadDetail, BooleanInput } from "../../../components";
import { useModal } from "../../../context";
import { customToast, formatCurrency } from "../../../utils";
import { arrayBill, downloadBase64File } from "../../../utils/helpers";
import { client_EB_api } from "../../../graphql/client";
import { GET_MANY_PDFS } from "../../../graphql";
import { useElectronic } from "../../../context/ElectronicContext";
import { ElectronicBillErrors } from "../../../Enums/Errors";

function IncomeCard({ incomeData, onCardCheckedChange }) {
  const { LineDetailPlace } = useElectronic();
  const { setModalOpen: setDetailModalOpen } = useModal();

  const handleDownload = async () => {
    const { data } = await client_EB_api.query({
      query: GET_MANY_PDFS,
      variables: {
        ids: incomeData?.id,
        place_id: LineDetailPlace?.id,
      },
    });
    if (data.downloadManyPDF[0].payload) {
      const { payload, params, type } = data.downloadManyPDF[0];
      downloadBase64File(payload, `${params[0]}.${type}`);
      return;
    }
    if (!data.downloadManyPDF[0].error) {
      return;
    }
    const { error } = data.downloadManyPDF[0];
    if (error === ElectronicBillErrors.NOT_FOUND) {
      customToast.error("El archivo no se encontró");
      return;
    }
    customToast.error("Error desconocido");
  };

  return (
    <Card
      key={incomeData?.Clave?._text}
      color="primary"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        minHeight: 338,
        gap: "0.6rem",
        padding: "1rem",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: incomeData.id ? "end" : "space-between",
        }}
      >
        {!incomeData.id && (
          <BooleanInput
            name={incomeData?.Clave?._text}
            value={incomeData?.selected}
            onChange={(name, value) => onCardCheckedChange?.(name, value || 0)}
          />
        )}
        <IconButton
          onClick={() =>
            setDetailModalOpen(
              true,
              <BillUploadDetail electronicDocument={arrayBill(incomeData)} />,
            )
          }
        >
          <VisibilityOutlinedIcon />
        </IconButton>
      </Box>
      <Box
        align="start"
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "0.1rem",
          overflowX: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          width: { xs: "200px", sm: "250px" },
          height: "26px",
        }}
      >
        <Typography fontWeight={600} typography="modeColor" variant="subtitle1">
          Receptor:
        </Typography>
        <Typography
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
          typography="modeColor"
          variant="subtitle1"
        >
          {incomeData?.Receptor?.NombreComercial?._text ||
            incomeData?.Receptor?.Nombre?._text ||
            "Sin receptor"}
        </Typography>
      </Box>
      <Typography align="start" typography="modeColor" variant="subtitle1">
        <span className="has-text-weight-bold"> Cédula: </span>{" "}
        {incomeData?.Receptor?.Identificacion?.Numero?._text || "---"}
      </Typography>
      <Typography typography="modeColor" variant="subtitle1">
        <span className="has-text-weight-bold"> Moneda: </span>{" "}
        {incomeData?.ResumenFactura?.CodigoTipoMoneda?.CodigoMoneda?._text}
      </Typography>
      <Typography typography="modeColor" variant="subtitle1">
        <span className="has-text-weight-bold"> Total: </span>{" "}
        {formatCurrency(
          parseFloat(incomeData.TotalNetSell, 10),
          incomeData?.ResumenFactura?.CodigoTipoMoneda?.CodigoMoneda?._text,
        )}
      </Typography>
      <Typography typography="modeColor" variant="subtitle1">
        <span className="has-text-weight-bold"> Fecha: </span>{" "}
        {format(new Date(incomeData?.FechaEmision?._text), "dd/MM/yyyy")}
      </Typography>
      <Typography typography="modeColor" variant="subtitle1">
        {format(new Date(incomeData?.FechaEmision?._text), "hh:mm a")}
      </Typography>
      {incomeData.id && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={e => {
              e.preventDefault();
              window.open(
                `/CreditDebitNote/${incomeData.id}/CreditNotes`,
                "_blank",
              );
            }}
          >
            Crear nota de crédito
          </Button>

          <IconButton size="large" onClick={() => handleDownload()}>
            <SimCardDownloadOutlinedIcon fontSize="large" />
          </IconButton>
        </Box>
      )}
    </Card>
  );
}

export default IncomeCard;

IncomeCard.propTypes = {
  incomeData: PropTypes.object,
  onCardCheckedChange: PropTypes.func,
};
IncomeCard.defaultProps = {
  incomeData: undefined,
  onCardCheckedChange: undefined,
};
