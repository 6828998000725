import axios from "axios";
import TransformImages from "./TransformImages";

export const withStopPropagation = (e, fn) => {
  e.stopPropagation();
  fn();
};
export const getUrl = item => {
  const route = item?.Files?.at(-1) || item?.url?.at(-1);
  return route?.Route_File || "";
};

export const validateRoute = item => {
  const route = getUrl(item);
  if (route === "") {
    return "https://firebasestorage.googleapis.com/v0/b/tavuel506.appspot.com/o/conta%2Fthumbs%2FNo%20Thumbnail%20Image-min_200x200.jpg?alt=media&token=0315aeb6-211e-4286-be23-39db27a896e7";
  }
  TransformImages(item?.Files || item?.url, 1);
  return route;
};

export const downloadBase64File = (contentBase64, fileName) => {
  const linkSource = `data:application/pdf;base64,${contentBase64}`;
  const downloadLink = document.createElement("a");
  document.body.appendChild(downloadLink);
  downloadLink.href = linkSource;
  downloadLink.target = "_self";
  downloadLink.download = fileName;
  downloadLink.click();
};
export const toZohoMonths = date => {
  const temporalDate = new Date(`${date} `).toDateString().split(" "); // Wed Sep 21 2022
  return `${temporalDate[2]}-${temporalDate[1]}-${temporalDate[3]}`;
};

export const arrayBill = bills => {
  if (bills.DetalleServicio?.LineaDetalle.constructor === Array) {
    return bills;
  }
  return {
    ...bills,
    DetalleServicio: { LineaDetalle: [bills.DetalleServicio.LineaDetalle] },
  };
};

export const isEmptyImage = url => !!url?.includes("emptyImg");

export const SORT_ARRAY_ITEMS_BY_DATE_MODES = {
  ASCENDANT: "ASCENDANT",
  DESCENDANT: "DESCENDANT",
};

export const sortArrayItemsByDate = (array, options) => {
  const { mode, targetKeyName } = options;
  const result = Array.isArray(array)
    ? array
        .slice()
        .sort(
          (firstComparisonElement, secondComparisonElement) =>
            (mode === SORT_ARRAY_ITEMS_BY_DATE_MODES.ASCENDANT &&
              new Date(secondComparisonElement[targetKeyName]) -
                new Date(firstComparisonElement[targetKeyName])) ||
            (mode === SORT_ARRAY_ITEMS_BY_DATE_MODES.DESCENDANT &&
              new Date(firstComparisonElement[targetKeyName]) -
                new Date(secondComparisonElement[targetKeyName])),
        )
    : [];

  return result;
};

export const formatDateString = date =>
  new Date(date).toISOString().match(/(\d+(\d+[:-])+\d+)/g);

export const get24HoursTime = date => {
  const options = { hour: "2-digit", minute: "2-digit", hour12: false };
  const formattedTime = new Intl.DateTimeFormat("en-GB", options).format(date);
  return formattedTime;
};

export const removeTypeName = item => {
  const temp_item = { ...item };
  delete temp_item.__typename;
  return temp_item;
};
export const removeFirstCharIfZero = value => {
  if (value === "") return "0";
  if (value[0] !== "0") return value;
  return value.slice(1) || value;
};

export const formatData = (data, fieldName, fieldId) => {
  if (fieldId === 0) {
    return data?.map(option => ({
      id: option[fieldId],
      name: option[fieldName],
    }));
  }
  return data?.map(option => ({
    id: option.id ?? option.Id,
    name: option[fieldName],
  }));
};

export const formatDataCountry = (data, fieldName) =>
  data?.map(option => ({
    id: option.id ?? option.Id,
    name: option[fieldName],
    phoneNumber: option.PhoneCode_Country,
  }));

export const getdate = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 18);
  return date.toISOString().substr(0, 10);
};

export const formatDataCountry2 = (data, fieldName) =>
  data?.map(option => ({
    id: option.id ?? option.Id,
    name: option[fieldName],
    PhoneCode_Country: option.PhoneCodCountry,
  }));

export const getFormRegister = () => ({
  Given_Name: {
    value: "",
    isTouch: false,
    onError: false,
    errors: [],
  },
  Family_Name: {
    value: "",
    isTouch: false,
    onError: false,
    errors: [],
  },
  Email: {
    value: "",
    isTouch: false,
    onError: false,
    errors: [],
  },
  Password: {
    value: "",
    isTouch: false,
    onError: false,
    errors: [],
  },
  PasswordConfirm: {
    value: "",
    isTouch: false,
    onError: false,
    errors: [],
  },
  BirthDate_Person: {
    value: getdate(),
    isTouch: true,
    onError: false,
    errors: [],
  },
  Phone_Number: {
    value: "+506",
    isTouch: false,
    onError: false,
    errors: [],
  },
  FK_Coin: {
    value: "1",
    isTouch: true,
    onError: false,
    errors: [],
  },
  FK_Country: {
    value: "CR",
    isTouch: true,
    onError: false,
    errors: [],
  },
  AcceptTerms: {
    value: false,
    isTouch: false,
    onError: false,
    errors: [],
  },
});

const validatorGivenName = value => {
  const errors = [];
  if (value.length < 3) {
    errors.push("El nombre del encargado es obligatorio.");
  }
  return errors;
};

const validatorFamilyName = value => {
  const errors = [];
  if (value.length < 3) {
    errors.push("El apellido del encargado es obligatorio.");
  }
  return errors;
};
const validatorPasswordConfirm = (value, valueAux) => {
  const errors = [];
  if (value !== valueAux) {
    errors.push("La contraseña y confirmación de contraseñas deben coincidir");
  }
  return errors;
};
const validatorPassword = value => {
  const errors = [];
  if (value.length < 8) {
    errors.push("La contraseña debe tener 8 caracteres como minimo.");
  }
  return errors;
};
const validatorEmail = value => {
  const errors = [];
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(value)) {
    errors.push("El formato del correo no es válido.");
  }

  return errors;
};
export const validator = (name, value, valueAux) => {
  switch (name) {
    case "Email":
      return validatorEmail(value);
    case "Given_Name":
      return validatorGivenName(value);
    case "Family_Name":
      return validatorFamilyName(value);
    case "PasswordConfirm":
      return validatorPasswordConfirm(value, valueAux);
    case "Password":
      return validatorPassword(value);
    default:
      return [];
  }
};

export async function getIsUserRegisteredInHacienda(identification) {
  try {
    const response = await axios.get(
      `https://api.hacienda.go.cr/fe/ae?identificacion=${identification}`,
    );
    return response.data?.situacion?.estado === "Inscrito";
  } catch (error) {
    return false;
  }
}
