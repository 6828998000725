import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// External components
import { toast } from "react-toastify";
import { Box, Grid, useTheme } from "@mui/material";
// Components
import {
  ExportationProductDataDisplayer,
  QuickGrid,
  TitledCard,
} from "./components";
import {
  ClientCard,
  PaymentDataCard,
  RegisterReceiver,
} from "../../components";
// Context
import { useElectronic } from "../../context/ElectronicContext";
// Hook
import useExportationBill from "../../hooks/useExportationBill";
// Enums
import { ElectronicBillTypes } from "../../Enums/ElectronicBill";
// Scss
import "./ExportationBillPage.scss";
import FormWithButtons from "../../components/FormWithButttons/FormWithButtons";
import { useModal } from "../../context";
import { PROVIDER_GROUP } from "../../Enums/General";
import useUsers from "../../hooks/useUsers";
import { customToast } from "../../utils";

function ExportationBillPage() {
  const {
    receiver,
    useCoinDetail,
    lineDetail,
    setLineDetail,
    resetReceiver,
    isBilling,
    setIsBilling,
    resetExportationBasicInfo,
    LineDetailPlace,
    setAddress,
    setClientGroup,
    setReceiver,
  } = useElectronic();
  const { handleCreatDraftFEE, handleCreateFEE } = useExportationBill();
  const navigate = useNavigate();
  const { setModalOpen } = useModal();
  const { addNewClient, dataClientsGroups, createGroup, refetchGroups } =
    useUsers();
  const theme = useTheme();
  const { resetDefaultCoin } = useCoinDetail;

  const validateExportationElectronicBill = () => {
    if (lineDetail.length === 0) return false;
    return true;
  };

  const createDraftExportationElectronicBill = async () => {
    const savedDraftBill = await handleCreatDraftFEE();

    if (!savedDraftBill?.data?.createDraftFEE) {
      toast.error('Ocurrió un error al generar la factura."');
    }

    return savedDraftBill;
  };

  const createExportationElectronicBill = async () => {
    const responseDraft = await createDraftExportationElectronicBill();
    if (!responseDraft?.data?.createDraftFEE?.id) return;
    const responseFEE = await handleCreateFEE(
      responseDraft?.data?.createDraftFEE?.id,
    );

    if (
      !responseFEE?.data?.createExportationBill ||
      responseFEE?.data?.createExportationBill?.error
    ) {
      toast.error(
        "Ocurrió un error al guardar la factura electrónica de exportación",
      );
      return;
    }
    toast.success(
      "Se ha guardado exitosamente la factura electrónica de exportación",
    );
    navigate(
      `/ElectronicBillView/${responseFEE.data.createExportationBill.id}/created`,
    );
  };

  const addNewClientAddapter = async profile => {
    const providersGroup = dataClientsGroups?.groups?.find(
      ({ Name_ClientGroup }) => Name_ClientGroup === PROVIDER_GROUP,
    );
    const selectedGroup =
      providersGroup?.id ||
      (
        await createGroup({
          Name_ClientGroup: PROVIDER_GROUP,
          FK_Place: LineDetailPlace.id,
        })
      )?.clientGroup?.id;
    refetchGroups();
    if (!selectedGroup) {
      return customToast.error("Ocurrió un error al crear el grupo");
    }
    const newClient = await addNewClient(profile, selectedGroup, "Proveedor");
    const client = newClient?.data?.client;
    const billingProfile = {
      id: client?.BillingProfile?.id,
      Receiver_Id: client?.BillingProfile?.ID_Number,
      Receiver_Name: client?.BillingProfile?.Name,
      Receiver_Email: client?.BillingProfile?.Email,
      Receiver_PhoneCode: client?.BillingProfile?.PhoneCodCountry,
      Receiver_PhoneNumber: client?.BillingProfile?.PhoneNumber,
      Receiver_PersonType: client?.BillingProfile?.MH_PersonType,
      user: { id: client?.BillingProfile?.User.id },
    };
    const address = {
      Province:
        client?.BillingProfile?.MH_Neighborhood?.District?.Canton?.Province,
      Canton: client?.BillingProfile?.MH_Neighborhood?.District?.Canton,
      District: client?.BillingProfile?.MH_Neighborhood?.District,
      Neighborhood: client?.BillingProfile?.MH_Neighborhood,
      Other: client?.BillingProfile?.OtherSigns,
    };

    setAddress(address);
    setClientGroup({ id: 0 });
    setModalOpen(false);
    return setReceiver(billingProfile);
  };

  const handleOpenModal = () => {
    setModalOpen(
      true,
      <Box
        className="modal-content-size container-modal "
        sx={{
          height: { xs: "600px", sm: "100%" },
          width: { xs: "333px", sm: "100%" },
        }}
      >
        <RegisterReceiver
          newClient
          FK_User={0}
          handleChangeAddress={() => null}
          handleCreateNewClient={addNewClientAddapter}
          handleOnClosed={() => setModalOpen(false)}
          initialReceiver={null}
          setBillingProfile={null}
          title="Perfil de facturación"
        />
      </Box>,
    );
  };

  useEffect(() => {
    if (!isBilling) {
      resetReceiver();
      resetDefaultCoin();
      resetExportationBasicInfo();
      setLineDetail([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => {
      if (isBilling) setIsBilling(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isBilling],
  );

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <Box
          sx={{
            paddingY: 4,
            paddingX: 3,
            borderRadius: 1,
            border: `1px solid ${theme.palette.primary.dark}`,
          }}
        >
          <QuickGrid
            data={[
              { label: "Consecutiva", value: "00100001090000000011" },
              {
                label: "Clave",
                value: "50601072400310675993400100001090000000011127570658",
              },
              { label: "Código de la actividad", value: "123456" },
              { label: "Condición de Venta", value: "Contado" },
              {
                label: "Medio de Pago",
                value: "Tranferencia-depósito bancario",
              },
              { label: "Fecha", value: "01/08/2024" },
            ]}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <TitledCard title="Datos del Emisor">
          <QuickGrid
            data={[
              {
                label: "Nombre",
                value: "Tavuel Cinco Cero Seis Sociedad Civil",
              },
              { label: "Cédula", value: "3206759934" },
              { label: "E-mail", value: "info@tavuel.com" },
              { label: "Teléfono", value: "+506 2456 6789" },
              { label: "Provincia", value: "San Jose" },
              { label: "Canton", value: "Perez Zeledon" },
              { label: "Distrito", value: "San Isidro del General" },
              { label: "Otras señas", value: "Segunda casa color azul" },
            ]}
          />
        </TitledCard>
      </Grid>
      <Grid item md={6} xs={12}>
        <ClientCard
          data={{
            Id: receiver?.Receiver_Id,
            Name: receiver?.Receiver_Name,
            Email: receiver?.Receiver_Email,
            PhoneNumber: receiver?.Receiver_PhoneNumber,
          }}
          optional={
            receiver.Receiver_Id === ""
              ? "Click para buscar (opcional)"
              : ` ${receiver?.Receiver_Name}, 
                                ${receiver?.Receiver_Id}`
          }
          optional2={
            receiver?.Receiver_Email === "" &&
            receiver?.Receiver_PhoneNumber === ""
              ? ""
              : ` ${receiver?.Receiver_Email}, 
                              ${receiver?.Receiver_PhoneNumber}`
          }
          sx={{
            height: "100%",
          }}
          type={ElectronicBillTypes.FacturaElectronica}
          onClickSubmit={() => handleOpenModal()}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <PaymentDataCard
          sx={{
            height: "100%",
          }}
          type={ElectronicBillTypes.FacturaElectronica}
        />
      </Grid>
      <Grid item xs={12}>
        <ExportationProductDataDisplayer />
      </Grid>
      <Grid item xs={12}>
        <FormWithButtons
          buttonsAlignment="end"
          disableSubmit={!validateExportationElectronicBill()}
          submitText="Generar Factura"
          onSubmit={createExportationElectronicBill}
        />
      </Grid>
    </Grid>
  );
}

export default ExportationBillPage;
