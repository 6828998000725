/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import { Card, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import fileIcon from "../../../assets/icons/file_icon.svg";

function IncomesDropper({ onFilesDrop }) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop: onFilesDrop, accept: ".xml" });

  return (
    <Card
      color="secondaryFile"
      {...getRootProps({
        className: `dropzone
            ${isDragAccept && "dropzoneAccept"}
            ${isDragReject && "dropzoneReject"}`,
      })}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "1rem",
        width: "100%",
        height: "100%",
        minHeight: 338,
        padding: "1rem",
      }}
    >
      <div className="content-flex-column">
        <img
          alt="img"
          src={fileIcon}
          style={{ widows: "76px", height: "90px" }}
        />
        <input {...getInputProps()} />
        {isDragActive ? (
          <Typography typography="defaultLight" variant="subtitle1">
            Suelte los archivos aquí...
          </Typography>
        ) : (
          <Typography
            align="center"
            typography="defaultLight"
            variant="subtitle1"
          >
            Arrastre los archivos .xml aquí, o click para seleccionar archivos
          </Typography>
        )}
      </div>
    </Card>
  );
}

export default IncomesDropper;

IncomesDropper.propTypes = {
  onFilesDrop: PropTypes.func.isRequired,
};
IncomesDropper.defaultProps = {};
