import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";

function SimpleTable({ headerRow, dataRows, sx }) {
  const theme = useTheme();

  return (
    <TableContainer
      sx={{
        "borderRadius": 2,
        "paddingX": 2,
        "border": `1px solid ${theme.palette.primary.dark}`,
        "overflowX": "auto",
        "&::-webkit-scrollbar": {
          height: 8,
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(0, 0, 0, 0.25)",
          borderRadius: 1,
        },
        ...sx,
      }}
    >
      <Table sx={{ minWidth: 650 }}>
        <TableHead sx={{ minHeight: 30 }}>
          <TableRow
            sx={{
              "td, th": {
                borderWidth: 2,
                borderColor: theme.palette.text.primary,
              },
            }}
          >
            {headerRow.map(headerCol => (
              <TableCell>
                <b>{headerCol}</b>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataRows.map(data => (
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "td, th": {
                  borderWidth: 2,
                  borderColor: theme.palette.text.primary,
                },
              }}
            >
              {data.map(dataCol => (
                <TableCell sx={{ height: 50 }}>{dataCol}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

SimpleTable.propTypes = {
  headerRow: PropTypes.array.isRequired,
  dataRows: PropTypes.array.isRequired,
  sx: PropTypes.object,
};
SimpleTable.defaultProps = {
  sx: undefined,
};

export default SimpleTable;
