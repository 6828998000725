import { Card } from "@mui/material";
import PropTypes from "prop-types";

function TitledCard({ title, children, sx }) {
  return (
    <Card
      color="primary"
      sx={{
        paddingY: 4,
        paddingX: 3,
        ...sx,
      }}
    >
      {title && <h1>{title}</h1>}
      {children}
    </Card>
  );
}

TitledCard.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  sx: PropTypes.object,
};
TitledCard.defaultProps = {
  title: undefined,
  sx: undefined,
};

export default TitledCard;
