import { useState } from "react";
import { useTheme } from "@emotion/react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Collapse,
} from "@mui/material";

function ExpandableCard({ headerContent, children, sx }) {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      sx={{
        ...sx,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        backgroundColor:
          theme.palette.mode === "dark"
            ? "transparent"
            : theme.palette.background.default,
      }}
    >
      <CardActions sx={{ width: "100%" }}>
        <Button
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            color: theme.palette.text.primary,
          }}
          variant="text"
          onClick={handleExpandClick}
        >
          {headerContent}
        </Button>
      </CardActions>
      <Collapse unmountOnExit in={expanded} timeout="auto">
        <CardContent>{children}</CardContent>
      </Collapse>
    </Card>
  );
}

ExpandableCard.propTypes = {
  children: PropTypes.node.isRequired,
  headerContent: PropTypes.node.isRequired,
  sx: PropTypes.object,
};
ExpandableCard.defaultProps = {
  sx: undefined,
};

export default ExpandableCard;
